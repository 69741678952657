import React from "react";
import PropTypes from "prop-types";

import LinkDefault from "../Links/LinkDefault";

const HeroButtons = ({ data }) => {
  const buttonConfig = [
    {
      content: data.button_content1,
      url: data.button_url1.cached_url,
      isBlank: data.button_url1.linktype !== "story",
    },
    {
      content: data.button_content2,
      url: data.button_url2.cached_url,
      isBlank: data.button_url2.linktype !== "story",
    },
    {
      content: data.button_content3,
      url: data.button_url3.cached_url,
      isBlank: data.button_url3.linktype !== "story",
    },
  ];

  return (
    <ul className="list-links">
      {buttonConfig.map((button) => {
        return button.url ? (
          <li key={button.content + button.url}>
            <LinkDefault
              text={button.content}
              isWhiteBorder={true}
              href={button.url}
              isBlank={button.isBlank}
            />
          </li>
        ) : null;
      })}
    </ul>
  );
};

HeroButtons.propTypes = {
  data: PropTypes.shape({
    button_content1: PropTypes.string,
    button_content2: PropTypes.string,
    button_content3: PropTypes.string,
    button_url1: PropTypes.object,
    button_url2: PropTypes.object,
    button_url3: PropTypes.object,
  }),
};

export default HeroButtons;
